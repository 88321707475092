import React, {Fragment, useEffect, useState} from 'react';
import s from './UsersPage.module.scss';
import PageHeader from "../../components/PageHeader";
import SearchInput from "../../components/UI/Inputs/SearchInput";
import Table from "../../components/UI/Table";
import {ITableDataHeader} from "../../components/UI/Table/Table";
import TableColumn from "../../components/UI/Table/components/TableColumn/TableColumn";
import TableRow from "../../components/UI/Table/components/TableRow";
import {Link} from "react-router-dom";
import {ENDPOINTS} from "../../api/endpoints";
import {UserInfo} from "../../api/interface";
import Loader from "../../components/UI/Loader";
import {Helmet} from "react-helmet";
import Button from "../../components/UI/Button";
import Cookies from "js-cookie";
import {setIsAuthenticated} from "../../store/reducers/authReducer";

export default function UsersPage() {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [users, setUsers] = useState<UserInfo[]>([])

    useEffect(() => {
        ENDPOINTS.USERS.GET().then((res) => {
            setUsers(res)
            setIsLoading(false)
        })
    }, []);

    const tableGrid = ['60px', '1fr', '1fr', '1fr', '160px', '160px', '160px'];
    const headerData: ITableDataHeader[] = [
        {title: 'ID'},
        {title: 'Email'},
        {title: 'Баланс'},
        {title: 'Роль'},
        {title: ''},
        {title: ''},
    ];

    const [name, setName] = useState<string>('');


    const currentItems = users.filter(o => {
        return o.email ? o.email.toLowerCase().indexOf(name.toLowerCase()) !== -1 : true
    });

    const onEnterToUserForAdmin = (id: number) => {
        ENDPOINTS.AUTH.LOGIN_ADMIN({id}).then((res) => {
            Cookies.set('token', res.data.access_token);
            window.location.reload()
        })
    }

    return (
        <div className={s.container}>
            <Helmet>
                <title>Пользователи | Shark Cpa</title>
            </Helmet>
            <PageHeader title={"Пользователи"} childrenPosition={"end"}>
                <SearchInput placeholder={"Поиск по Email"} onChange={(e) => setName(e.target.value)} value={name}/>
            </PageHeader>

            <div className={s.container__products}>
                <Table className={s.container__products_table} dataHeaders={headerData} gridWidthSize={tableGrid}>
                    {headerData && <div className={s.underlined}/>}
                    {currentItems.map((item, index) => (
                        <Fragment key={index}>
                            <TableRow gridWidthSize={tableGrid} url={`/users/${item.id}`} hover={true}>
                                <TableColumn>
                                    <div>{item.id}</div>
                                </TableColumn>
                                <TableColumn>
                                    <div className={s.title}>{item.email}</div>
                                </TableColumn>
                                <TableColumn>
                                    <div>{item.balance} ₽</div>
                                </TableColumn>
                                <TableColumn>
                                    <div>{item.rights === "ADMIN" ? "Администратор" : "Пользователь"}</div>
                                </TableColumn>
                                <TableColumn>
                                    <Link
                                        to={`/reports/stats1/time/${item.id}`}
                                        className={s.hoverItem}>Статистика</Link>
                                </TableColumn>
                                <TableColumn>
                                    <Link
                                        to={`/users/${item.id}`}
                                        className={s.hoverItem}>Редактировать</Link>
                                </TableColumn>

                                <TableColumn>
                                    <Button className={s.hoverItem} onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        onEnterToUserForAdmin(item.id)
                                    }}>Войти</Button>
                                </TableColumn>
                            </TableRow>
                        </Fragment>
                    ))}

                </Table>
                {isLoading && <Loader/>}
            </div>
        </div>
    )
}
