import React, {Fragment, useEffect, useState} from 'react';

import s from './LeadsCreatePage.module.scss'
import {useParams} from "react-router-dom";
import {ITableDataHeader} from "../../components/UI/Table/Table";
import FormInput from "../../components/UI/Inputs/FormInput";
import Button from "../../components/UI/Button";
import PageHeader from "../../components/PageHeader";
import Table from "../../components/UI/Table";
import TableRow from "../../components/UI/Table/components/TableRow";
import TableColumn from "../../components/UI/Table/components/TableColumn/TableColumn";
import SearchInput from "../../components/UI/Inputs/SearchInput";
import {ENDPOINTS} from "../../api/endpoints";
import {toast} from "react-toast";
import {Leads} from "../../api/interface/Leads";
import {Links} from "../../api/interface/Links";
import Loader from "../../components/UI/Loader";
import copyTextToClipboard from "../../utils/copyTextToClipboard";
import check from "../../assets/icons/check.svg";
import {Helmet} from "react-helmet";

export default function LeadsCreatePage() {
    const tableGrid = ['1fr', '1fr', '100px', '120px', '100px'];
    const [name, setName] = useState<string>('');
    const {id} = useParams()
    const [lead, setLead] = useState<Leads | undefined>(undefined);
    const [links, setLinks] = useState<Links[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const headerData: ITableDataHeader[] = [
        {title: 'Название'},
        {title: 'Ссылка'},
        {title: 'Переход'},
        {title: 'Конверсия'},
        {title: 'Оплата'},
    ];

    useEffect(() => {
        if(id) {
            ENDPOINTS.LINKS.GET_BY_ID_LEAD(id).then((_links) => {
                setLinks(_links)
                setIsLoading(false)
            })
        }
    }, [id]);

    const currentItems = links.filter(o => {
        return o.offer.title ? o.offer.title.toLowerCase().indexOf(name.toLowerCase()) !== -1 : true
    });

    function createOrUpdate(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        const formData = new FormData(e.currentTarget);

        if (id) { // Update
            ENDPOINTS.LEADS.UPDATE({
                id: parseInt(id),
                username: formData.get('username')
            }).then(() => {
                toast.success('Успешно обновлено.')
            }).catch(() => {
                toast.error('Упс. Что-то пошло не так. Попробуйте позже.')
            })
        } else { // Create
            ENDPOINTS.LEADS.CREATE({
                username: formData.get('username')
            }).then((res) => {
                window.location.replace(`/leads/${res.id}`)
                toast.success('Успешно создано.')
            }).catch(() => {
                toast.error('Упс. Что-то пошло не так. Попробуйте позже.')
            })
        }
    }

    function remove(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault()

        if (id) {
            const ready = window.confirm('Вы уверены? Данные будут стерты навсегда')
            if (ready) {
                ENDPOINTS.LEADS.DELETE(id).then(() => {
                    toast.success('Успешно удалено.')
                    window.location.replace(`/leads`)
                }).catch(() => {
                    toast.error('Упс. Что-то пошло не так. Попробуйте позже.')
                })
            }
        }
    }

    useEffect(() => {
        if (id) {
            ENDPOINTS.LEADS.GET_BY_ID(id).then((res) => {
                setLead(res)
            })
        }
    }, [id])

    return (
        <div className={s.container}>
            <Helmet>
                <title>{id && lead ? lead.username : 'Создать лид'} | Shark Cpa</title>
            </Helmet>
            <div className={s.container__content}>
                <form onSubmit={createOrUpdate} className={s.container__content__leftPart}>
                    <div className={s.container__content__leftPart__inputs}>
                        {id && lead && <FormInput name={"username"} text="ФИО" defaultValue={lead.username}/>}
                        {!id && <FormInput name={"username"} text="ФИО"/>}
                    </div>
                    <div className={s.container__content__leftPart__buttons}>
                        <Button onClick={() => {
                        }} type={"contained"} role={"submit"}>
                            {id ? "Сохранить" : "Создать"}
                        </Button>
                        {id && <Button onClick={remove} type={"outlined"} role={"button"}>
                            <span>Удалить</span>
                        </Button>}
                    </div>
                </form>
                {id && lead && <div className={s.container__content__rightPart}>
                    <PageHeader title={"Офферы"} text="Редактировать" url={`/leads/${id}/create/link`}
                                childrenPosition={"end"}>
                        <SearchInput onChange={(e) => setName(e.currentTarget.value)} value={name}/>
                    </PageHeader>

                    <div className={s.container__content__rightPart__products}>
                        <Table dataHeaders={headerData} className={s.container__content__rightPart__products_table} gridWidthSize={tableGrid}>
                            {headerData && <div className={s.underlined}/>}
                            {currentItems.map((item, index) => (
                                <Fragment key={index}>
                                    <TableRow gridWidthSize={tableGrid}>
                                        <TableColumn>
                                            <div className={s.title}><img src={item.offer.logo} alt=""/>{item.offer.title}</div>
                                        </TableColumn>
                                        <TableColumn>
                                            <div className={s.title}>
                                                <button title={`Копировать https://shark-cpa.ru/link/${item.custom_link}`} onClick={(e) => {
                                                    e.preventDefault()
                                                    copyTextToClipboard(`https://shark-cpa.ru/link/${item.custom_link}`)
                                                }} className={s.hoverItem}>https://shark-cpa.ru/link/{item.custom_link}</button>
                                            </div>
                                        </TableColumn>
                                        <TableColumn>
                                            {item.is_transition && <img title={'Был переход'} className={s.check} src={check} alt=""/>}
                                        </TableColumn>
                                        <TableColumn>
                                            {item.is_conversation && <img title={'Есть конверсия'} className={s.check} src={check} alt=""/>}
                                        </TableColumn>
                                        <TableColumn>
                                            {item.is_pay && <img title={'Была оплата'} className={s.check} src={check} alt=""/>}
                                        </TableColumn>
                                    </TableRow>
                                </Fragment>
                            ))}

                        </Table>
                        {isLoading && <Loader/>}
                    </div>
                </div>}
            </div>
        </div>
    )
}
