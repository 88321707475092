import React, {FormEvent, useRef} from 'react';
import s from './OffersCreatePageNew.module.scss';
import PageHeader from "../../components/PageHeader";
import {Helmet} from "react-helmet";
import FormInput from "../../components/UI/Inputs/FormInput";
import Button from "../../components/UI/Button";
import {ENDPOINTS} from "../../api/endpoints";
import {toast} from "react-toast";

export default function OffersCreatePageNew() {
    const formRef = useRef<HTMLFormElement>(null)

    function create() {
        if (formRef.current) {
            const formData = new FormData(formRef.current)
            ENDPOINTS.OFFERS.CREATE_NEW({
                title: formData.get('title') || undefined,
                leadgid_id: formData.get('leadgid_id') || undefined,
                image: formData.get('image') || undefined
            }).then((res) => {
                toast.success('Успешно создано')
            }).catch(() => {
                toast.error('Упс. Что-то пошло не так, попробуйте позже.')
            })
        }
    }

    return (
        <div className={s.container}>
            <Helmet>
                <title>Создать новый оффер | Shark Cpa</title>
            </Helmet>

            <PageHeader title={"Создать новый оффер"}/>

            <div className={s.container__content}>
                <form ref={formRef} onSubmit={(e) => {
                    e.preventDefault();
                    create()
                }}>
                    <div className={s.container__content__inputs}>
                        <FormInput name={"title"} text="Название *" required/>
                        <FormInput name={"leadgid_id"} type={'number'} text="LeadGid ID"/>
                        <FormInput name={"image"} text="Ссылка на изображение"/>
                    </div>
                    <div className={s.container__content__buttons}>
                        <Button type={"contained"} role={"submit"}>Создать</Button>
                    </div>
                </form>
            </div>
        </div>
    )
}
