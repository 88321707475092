import React, {Fragment, useEffect, useState} from 'react';
import s from './CommunicationLeadPage.module.scss';
import {toast} from "react-toast";
import {ITableDataHeader} from "../../../components/UI/Table/Table";
import {Offers} from "../../../api/interface/Offers";
import {ENDPOINTS} from "../../../api/endpoints";
import PageHeader from "../../../components/PageHeader";
import SearchInput from "../../../components/UI/Inputs/SearchInput";
import Table from "../../../components/UI/Table";
import Loader from "../../../components/UI/Loader";
import TableRow from "../../../components/UI/Table/components/TableRow";
import TableColumn from "../../../components/UI/Table/components/TableColumn/TableColumn";
import FormInput from "../../../components/UI/Inputs/FormInput";
import {useParams} from "react-router-dom";
import {Links} from "../../../api/interface/Links";
import {useAppSelector} from "../../../store";
import {Helmet} from "react-helmet";

export default function CommunicationLeadPage() {
    const {rights, id} = useAppSelector((state) => state.profile)
    const tableGrid = ['auto', '100px'];
    const headerData: ITableDataHeader[] = [
        {title: 'Название'},
    ];

    const [offers, setOffers] = useState<Links[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const [name, setName] = useState<string>('');

    const currentItems = offers.filter(o => {
        return o.offer.title ? o.offer.title.toLowerCase().indexOf(name.toLowerCase()) !== -1 : true
    });

    const params = useParams()

    function getOffers(callback?: () => void) {
        if (id && params.id) {
            setOffers([])
            setIsLoading(true)

            ENDPOINTS.LINKS.GET_BY_ID_USER_LEAD(id, params.id).then((_links) => {
                setOffers(_links)
                setIsLoading(false)

                callback && callback()
            })
        }
    }

    useEffect(() => {
        getOffers()
    }, []);

    const saveOffer = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (params.id) {
            const formData = new FormData(e.currentTarget)
            const original_link = formData.get('original_link')

            if(rights && rights !== "ADMIN") {
                if (original_link && original_link.toString().indexOf('shark-cpa.ru') === -1) {
                    return toast.error('Не верный формат ссылки. Ссылка должна ссылаться на shark-cpa.ru')
                }
            }

            ENDPOINTS.LINKS.CREATE_OR_UPDATE({
                original_link: formData.get('original_link'),
                offer_id: formData.get('offer_id'),
                lead_id: params.id,
                user_id: formData.get('user_id'),
            }).then((res) => {
                getOffers(() => {
                    toast.success('Успешно обновлено.')
                })
            }).catch((e) => {
                console.log(e)
                toast.error('Упс. Что-то пошло не так, попробуйте позже.')
            })
        }
    }

    const deleteLink = (id?: number) => {
        if (id) {
            const ready = window.confirm('Вы уверены? Данные будут стерты навсегда')
            if (ready) {
                ENDPOINTS.LINKS.REMOVE(id).then(() => {
                    getOffers(() => {
                        toast.success('Успешно удалено.')
                    })
                }).catch(() => toast.error('Упс. Что-то пошло не так, попробуйте позже.'))
            }
        }
    }

    return (
        <div className={s.container}>
            <Helmet>
                <title>{"Добавить офферы для лида: " + params.id} | Shark Cpa</title>
            </Helmet>
            <PageHeader title={"Добавить офферы для лида: " + params.id} childrenPosition={"end"}>
                <SearchInput onChange={(e) => setName(e.currentTarget.value)} value={name}/>
            </PageHeader>

            <div className={s.container__products}>
                <Table dataHeaders={headerData} className={s.container__products_table} gridWidthSize={tableGrid}>
                    {headerData && <div className={s.underlined}/>}
                    {currentItems.map((item, index) => (
                        <Fragment key={index}>
                            <form onSubmit={saveOffer}>
                                <TableRow gridWidthSize={tableGrid}>
                                    <TableColumn>
                                        <input type={'hidden'} name={"offer_id"} value={item.offer.id}/>
                                        <input type={'hidden'} name={"user_id"} value={id}/>
                                        <input type={'hidden'} name={"original_link"} value={`https://shark-cpa.ru/link/${item?.custom_link}`}/>

                                        <div className={s.title}><img src={item.offer.logo} alt={"card"}/>
                                            <span>{item.offer.title}</span></div>
                                    </TableColumn>
                                    <TableColumn>
                                        {item?.is_connect ? <button type={"button"} onClick={() => deleteLink(item.id)}
                                                                    className={s.hoverItem}>Удалить</button>  :
                                            <button type={"submit"}
                                                    className={s.hoverItem}>Добавить</button>}
                                    </TableColumn>
                                </TableRow>
                            </form>
                        </Fragment>
                    ))}
                </Table>
                {isLoading && <Loader/>}
            </div>
        </div>
    )
}
