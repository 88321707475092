import React, {Fragment, useEffect, useState} from 'react';
import s from './OffersCreatePage.module.scss';
import PageHeader from "../../components/PageHeader";
import SearchInput from "../../components/UI/Inputs/SearchInput";
import Table from "../../components/UI/Table";
import {ITableDataHeader} from "../../components/UI/Table/Table";
import TableColumn from "../../components/UI/Table/components/TableColumn/TableColumn";
import TableRow from "../../components/UI/Table/components/TableRow";
import {ENDPOINTS} from "../../api/endpoints";
import {toast} from "react-toast";
import Loader from "../../components/UI/Loader";
import {Helmet} from "react-helmet";
import TablePagination from "../../components/UI/Table/components/TablePagination";
import {DataXPartnersOffers} from "../../api/interface/Unicom";
import {useAppSelector} from "../../store";
import {Offers} from "../../api/interface/Offers";

export default function OffersCreatePage() {
    const DATA_PAGE_LIMIT: number = 20
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isMyLoading, setMyIsLoading] = useState<boolean>(true)
    const [search, setSearch] = useState<string>('');
    const [searchString, setSearchString] = useState<string>('');
    const {rights, id} = useAppSelector((state) => state.profile)

    const [rawData, setRawData] = useState<DataXPartnersOffers>({
        data: [],
        pagination: {
            count: 0,
            limit: DATA_PAGE_LIMIT,
            page: currentPage
        }
    })
    const [rawMyData, setMyRawData] = useState<Offers[]>([])

    const tableGrid = ['120px', '1fr', '160px'];
    const headerData: ITableDataHeader[] = [
        {title: 'ID'},
        {title: 'Название'},
        {title: ''},
    ];

    useEffect(() => {
        getMyData()
    }, []);

    useEffect(() => {
        getData(currentPage)
    }, [currentPage, searchString]);

    useEffect(() => {
        const timeOutId = setTimeout(() => setSearchString(search), 500);
        return () => clearTimeout(timeOutId);
    }, [search]);


    async function createOrRemoveOffer(id: number) {
        await ENDPOINTS.OFFERS.CREATE_OR_REMOVE(id)
        toast.success('Успешно выполнено')

        setRawData((prevState) => {
            const data = prevState.data.map((offer) => {
                if (offer.id === id) {
                    return {
                        id: offer.id,
                        inner_id: offer.inner_id,
                        title: offer.title,
                        logo: offer.logo,
                        is_connect: !offer.is_connect
                    }
                }
                return offer
            })

            return {
                data: data,
                pagination: prevState.pagination
            }
        })
    }
    async function deleteMyOffer(id: number) {
        await ENDPOINTS.OFFERS.DELETE_NEW(id)
        toast.success('Успешно выполнено')

        setMyRawData((prevState) => {
            return prevState.filter((offer) => {
                if (offer.id !== id) {
                    return offer
                }
            })
        })
    }

    function getData(page: number) {
        setRawData({data: [], pagination: {}})
        setIsLoading(true)

        ENDPOINTS.UNICOM_OFFERS.GET(page, DATA_PAGE_LIMIT, searchString).then((res) => {
            setRawData(res)
            setIsLoading(false)
        })
    }
    function getMyData() {
        setMyRawData([])
        setMyIsLoading(true)

        ENDPOINTS.OFFERS.GET_MY().then((res) => {
            setMyRawData(res)
            setMyIsLoading(false)
        })
    }

    return (
        <div className={s.container}>
            <Helmet>
                <title>Менеджер офферов | Shark Cpa</title>
            </Helmet>

            <PageHeader title={"Менеджер офферов"} text={rights === "ADMIN" ? "Создать новый" : undefined}
                        url={rights === "ADMIN" ? "/offers/create/new" : undefined} create={() => {
            }} childrenPosition={"end"}>
                <SearchInput onChange={(e) => setSearch(e.currentTarget.value)} value={search}/>
            </PageHeader>

            <div className={s.container__products}>
                <h4 className={s.container__products__title}>Мой офферы</h4>
                <Table dataHeaders={headerData} className={s.container__products_table} gridWidthSize={tableGrid}>
                    {headerData && <div className={s.underlined}/>}

                    {rawMyData?.length > 0 ? rawMyData?.map((item, index) => (
                        <Fragment key={index}>
                            <TableRow gridWidthSize={tableGrid}>
                                <TableColumn>
                                    <div>{item.id}</div>
                                </TableColumn>
                                <TableColumn>
                                    <img className={s.logo} src={item.logo} alt="Logo"/>
                                    <div className={s.title}>{item.title}</div>
                                </TableColumn>
                                <TableColumn>
                                    <button onClick={async (e) => {
                                        e.preventDefault()
                                        await deleteMyOffer(item.id)
                                    }} className={s.hoverItem}>
                                        Удалить
                                    </button>
                                </TableColumn>
                            </TableRow>
                        </Fragment>
                    )) : <p className={s.container__not_found}>Ничего не найдено</p>}

                </Table>

                {isMyLoading && <Loader/>}
            </div>

            <div className={s.container__products}>
                <h4 className={s.container__products__title}>X-Partners</h4>
                <Table dataHeaders={headerData} className={s.container__products_table} gridWidthSize={tableGrid}>
                    {headerData && <div className={s.underlined}/>}
                    {rawData?.data.map((item, index) => (
                        <Fragment key={index}>
                            <TableRow url={`https://my.x-partners.com/v2/offer/1608/${item.id}/general`}
                                      gridWidthSize={tableGrid} hover={true}>
                                <TableColumn>
                                    <div>{item?.inner_id}</div>
                                </TableColumn>
                                <TableColumn>
                                    <img className={s.logo} src={item.logo} alt="Logo"/>
                                    <div className={s.title}>{item.title}</div>
                                </TableColumn>
                                <TableColumn>
                                    <button onClick={async (e) => {
                                        e.preventDefault()
                                        await createOrRemoveOffer(item.id)
                                    }} className={s.hoverItem}>
                                        {item.is_connect ? 'Удалить' : 'Добавить'}
                                    </button>
                                </TableColumn>
                            </TableRow>
                        </Fragment>
                    ))}

                </Table>

                {isLoading && <Loader/>}

                <TablePagination currentPage={currentPage}
                                 totalPages={Math.round(Number((rawData?.pagination.count || 0) / DATA_PAGE_LIMIT))}
                                 onPageChange={(page) => {
                                     setCurrentPage(page)
                                 }}/>
            </div>
        </div>
    )
}
